import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import API from "../api";
import moment from "moment";
import "moment/locale/fr";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import { urlImage } from "../utils";
moment.locale("fr");

const TherapiePage = () => {
  const [therapie, setTherapie] = useState([]);

  const userInfo = JSON.parse(`${sessionStorage.getItem("userInfo")}`);

  useEffect(() => {
    API.get(`accompagnements`).then((res) => {
      const therapieFilter = res.data.data
        .filter((item) => item.type === "therapie")
        .sort(function (a, b) {
          return new Date(b.created) - new Date(a.created);
        });
      setTherapie(therapieFilter);
    });
  }, []);

  return (
    <div style={{ marginBottom: 16 }}>
      <Helmet>
        <title>Thérapie Coaching Angers</title>
        <meta name="description" content="Thérapie Coaching Angers" />
      </Helmet>

      <h2 className="center">Coaching / Thérapie</h2>
      <div className="picture-accompagnement">
        <img src="therapy.png" alt="therapy" />
      </div>
      <p className="presentation-text-accompagnement">
        Les séances peuvent avoir lieu en cabinet ou en visio.
      </p>

      <p className="presentation-text-accompagnement">
        <span style={{ textDecoration: "underline" }}>
          Mes accompagnements sont pour vous si :
        </span>
        <ul>
          <li>
            Vous traversez des{" "}
            <strong>
              difficultés relationnelles, personnelles, professionnelles
            </strong>
          </li>
          <li>
            Vous voulez <strong>gagner en confiance</strong>, en estime, en
            force intérieure
          </li>
          <li>
            Vous avez un <strong>projet</strong> (personnel ou professionnel)et
            souhaitez le concrétiser rapidement
          </li>
          <li>
            Vous souhaitez <strong>mieux vous connaître</strong>
          </li>
          <li>
            Vous avez décidé de <strong>rayonner</strong>, de
            <strong> vous déployer</strong>, de <strong>vous élever</strong>
          </li>
          <li>Vous voulez vous libérer de vos schémas répétitifs</li>
          <li>Vous êtes en période de transition et en quête de sens</li>
        </ul>
      </p>

      {therapie.length < 1 ? (
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      ) : (
        <div className="card-container">
          {therapie.map((item) => {
            return (
              <div key={item.id} className="card-content">
                <h3>{item.title}</h3>

                {item.image ? (
                  <img src={urlImage + item.image} alt="accompagnement" />
                ) : (
                  <img src="../default.jpg" alt="accompagnement" />
                )}

                <p className="card-description">{item.resume}</p>
                <ul>
                  <li>Durée : {item.duree}</li>

                  <li>Prix : {item.price}</li>
                </ul>

                <Link
                  to={`/accompagnements/${item.id}`}
                  className="btn right card-button"
                >
                  <span>Plus de détails</span>
                </Link>
              </div>
            );
          })}
        </div>
      )}

      {userInfo
        ? userInfo.admin === true && (
            <div className="container-new">
              <NavLink to="/AccompagnementsAdd">
                <button className="btn btn-new">
                  Ajouter un accompagnement
                </button>
              </NavLink>
            </div>
          )
        : ""}
    </div>
  );
};

export default TherapiePage;
