import { useState } from "react";
import {
  emailValidator,
  lastnameValidator,
  firstnameValidator,
  phoneValidator,
  addressValidator,
  cityValidator,
  countryValidator,
  passwordValidator,
  confirmPasswordValidator,
  messageValidator,
} from "./Validator";

// const touchErrors = (errors) => {
//   return Object.entries(errors).reduce((acc, [field, fieldError]) => {
//     acc[field] = {
//       ...fieldError,
//       dirty: true,
//     };
//     return acc;
//   }, {});
// };
export const useContactFormValidator = (form) => {
  const [errors, setErrors] = useState({
    email: {
      dirty: false,
      error: false,
      message: "",
    },
    lastname: {
      dirty: false,
      error: false,
      message: "",
    },
    firstname: {
      dirty: false,
      error: false,
      message: "",
    },
    address: {
      dirty: false,
      error: false,
      message: "",
    },
    city: {
      dirty: false,
      error: false,
      message: "",
    },
    phone_number: {
      dirty: false,
      error: false,
      message: "",
    },
    country: {
      dirty: false,
      error: false,
      message: "",
    },
    password: {
      dirty: false,
      error: false,
      message: "",
    },
    confirmPassword: {
      dirty: false,
      error: false,
      message: "",
    },
    message: {
      dirty: false,
      error: false,
      message: "",
    },
  });
  const validateForm = ({ form, field, errors, forceTouchErrors = false }) => {
    let isValid = true;

    // Create a deep copy of the errors
    const nextErrors = JSON.parse(JSON.stringify(errors));

    // Force validate all the fields
    // if (forceTouchErrors) {
    //   alert("Formulaire non valide");
    //   nextErrors = touchErrors(errors);
    // }

    const {
      email,
      lastname,
      firstname,
      address,
      city,
      country,
      phone_number,
      password,
      confirmPassword,
      message,
    } = form;

    if (nextErrors.email.dirty && (field ? field === "email" : true)) {
      const emailMessage = emailValidator(email, form);
      nextErrors.email.error = !!emailMessage;
      nextErrors.email.message = emailMessage;
      if (!!emailMessage) isValid = false;
    }

    if (nextErrors.firstname.dirty && (field ? field === "firstname" : true)) {
      const firstnameMessage = firstnameValidator(firstname, form);
      nextErrors.firstname.error = !!firstnameMessage;
      nextErrors.firstname.message = firstnameMessage;
      if (!!firstnameMessage) isValid = false;
    }

    if (nextErrors.lastname.dirty && (field ? field === "lastname" : true)) {
      const lastnameMessage = lastnameValidator(lastname, form);
      nextErrors.lastname.error = !!lastnameMessage;
      nextErrors.lastname.message = lastnameMessage;
      if (!!lastnameMessage) isValid = false;
    }

    if (nextErrors.message.dirty && (field ? field === "message" : true)) {
      const messageMessage = messageValidator(message, form);
      nextErrors.message.error = !!messageMessage;
      nextErrors.message.message = messageMessage;
      if (!!messageMessage) isValid = false;
    }

    if (nextErrors.address.dirty && (field ? field === "address" : true)) {
      const addressMessage = addressValidator(address, form);
      nextErrors.address.error = !!addressMessage;
      nextErrors.address.message = addressMessage;
      if (!!addressMessage) isValid = false;
    }

    if (nextErrors.city.dirty && (field ? field === "city" : true)) {
      const cityMessage = cityValidator(city, form);
      nextErrors.city.error = !!cityMessage;
      nextErrors.city.message = cityMessage;
      if (!!cityMessage) isValid = false;
    }

    if (nextErrors.country.dirty && (field ? field === "country" : true)) {
      const countryMessage = countryValidator(country, form);
      nextErrors.country.error = !!countryMessage;
      nextErrors.country.message = countryMessage;
      if (!!countryMessage) isValid = false;
    }

    if (
      nextErrors.phone_number.dirty &&
      (field ? field === "phone_number" : true)
    ) {
      const phone_numberMessage = phoneValidator(phone_number, form);
      nextErrors.phone_number.error = !!phone_numberMessage;
      nextErrors.phone_number.message = phone_numberMessage;
      if (!!phone_numberMessage) isValid = false;
    }

    if (nextErrors.password.dirty && (field ? field === "password" : true)) {
      const passwordMessage = passwordValidator(password, form);
      nextErrors.password.error = !!passwordMessage;
      nextErrors.password.message = passwordMessage;
      if (!!passwordMessage) isValid = false;
    }

    if (
      nextErrors.confirmPassword.dirty &&
      (field ? field === "confirmPassword" : true)
    ) {
      const confirmPasswordMessage = confirmPasswordValidator(
        confirmPassword,
        form
      );
      nextErrors.confirmPassword.error = !!confirmPasswordMessage;
      nextErrors.confirmPassword.message = confirmPasswordMessage;
      if (!!confirmPasswordMessage) isValid = false;
    }

    setErrors(nextErrors);

    return {
      isValid,
      errors: nextErrors,
    };
  };
  const onBlurField = (e) => {
    const field = e.target.name;
    const fieldError = errors[field];
    if (fieldError.dirty) return;

    const updatedErrors = {
      ...errors,
      [field]: {
        ...errors[field],
        dirty: true,
      },
    };

    validateForm({ form, field, errors: updatedErrors });
  };

  return {
    validateForm,
    onBlurField,
    errors,
  };
};
