/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import API from "../api";

const Administrator = () => {
  let location = useLocation();
  const idUser = location.state.id;
  const [accompagnementList, setAccompagnementList] = useState([]);
  const [user, setUser] = useState([]);

  const [accessUser, setAccessUser] = useState([]);

  const action = {
    creation: 1,
    updateClose: 2,
    updateOpen: 3,
  };

  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

  useEffect(() => {
    API.get(`accompagnements`).then((res) => {
      res.data.data.forEach((element) => {
        element.AccesUser = false;
      });
      setAccompagnementList(res.data.data);
    });
    API.get(`users/${idUser}`, {
      headers: { Authorization: `Bearer ${userInfo.token}` },
    }).then((res) => {
      setUser(res.data.data);
    });
    API.get(`accompagnements/access/${idUser}`, {
      headers: { Authorization: `Bearer ${userInfo.token}` },
    }).then((res) => {
      setAccessUser(res.data.data);
    });
  }, []);

  const dateNow = new Date();

  const handleOpenAccess = (accompagnementId, index, user) => {
    let accessManage = [...accompagnementList];
    const indexUser = accompagnementList[index].Users.map(
      (object) => object.id
    ).indexOf(idUser);

    const isUserPresent = accompagnementList.map((users) =>
      users.Users.filter((element) => element.id === idUser)
    ).length;

    if (!accompagnementList[index].Users[indexUser] || isUserPresent < 1) {
      accessManage[index].action = action.creation;
    } else {
      const dateAccess = new Date(
        accompagnementList[index].Users[indexUser].AccesUser.archive
      );
      const dateCompare = dateAccess > dateNow;
      if (dateCompare === true) {
        accessManage[index].action = action.updateClose;
      } else {
        accessManage[index].action = action.updateOpen;
      }
    }
    accessManage[index].idUser = idUser;
    setAccompagnementList(accessManage);
    handleSubmit(accompagnementId, index, indexUser);
  };

  const handleSubmit = (accompagnementId, index, indexUser) => {
    API.put(`accompagnements/${accompagnementId}`, accompagnementList[index], {
      headers: { Authorization: `Bearer ${userInfo.token}` },
    }).then((res) => {
      if (accompagnementList[index].Users[indexUser]) {
        accompagnementList[index].Users[indexUser].AccesUser.archive =
          res.data.data.Users[indexUser].AccesUser.archive;
        accompagnementList[index].AccesUser =
          new Date(res.data.data.Users[indexUser].AccesUser.archive) > dateNow;
        setAccompagnementList(accompagnementList);
      } else {
        accompagnementList[index].AccesUser =
          !accompagnementList[index].AccesUser;
        setAccompagnementList(accompagnementList);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    });
  };

  return (
    <div>
      <div className="table-container">
        <p>
          Utilisateur : {user.firstname} {user.lastname}
        </p>

        <table>
          <thead className="table-head">
            <tr>
              <th>Titre</th>

              <th>Durée</th>
              <th>Prix</th>
              <th>Etat</th>
            </tr>
          </thead>
          <tbody className="table-body">
            {accompagnementList.map((accompagnement, index) => {
              return (
                <tr key={accompagnement.id} className="table-row">
                  <td> {accompagnement.title} </td>

                  <td> {accompagnement.duree} </td>
                  <td> {accompagnement.price / 100} </td>
                  <td>
                    {accompagnementList[index]?.Users[
                      accompagnementList[index]?.Users.map(
                        (object) => object.id
                      ).indexOf(idUser)
                    ] ? (
                      <button
                        className={
                          (accompagnement.Users.length > 1 &&
                            accompagnement.Users.filter(
                              (users) => users.id === idUser
                            ).length > 1) ||
                          new Date(
                            accompagnementList[index]?.Users[
                              accompagnementList[index]?.Users.map(
                                (object) => object.id
                              ).indexOf(idUser)
                            ].AccesUser.archive
                          ) > dateNow
                            ? "btn button-available"
                            : "btn button-not-available"
                        }
                        onClick={() =>
                          handleOpenAccess(
                            accompagnement.id,
                            index,
                            accompagnement.Users.length
                          )
                        }
                      >
                        {(accompagnement.Users.length > 1 &&
                          accompagnement.Users.filter(
                            (users) => users.id === idUser
                          ).length > 1) ||
                        new Date(
                          accompagnementList[index]?.Users[
                            accompagnementList[index]?.Users.map(
                              (object) => object.id
                            ).indexOf(idUser)
                          ].AccesUser.archive
                        ) > dateNow
                          ? "Ouvert"
                          : "Fermé"}
                      </button>
                    ) : (
                      <button
                        className={
                          accompagnement.Users.length > 1 &&
                          accompagnement.AccesUser === true
                            ? "btn button-available"
                            : "btn button-not-available"
                        }
                        onClick={() =>
                          handleOpenAccess(
                            accompagnement.id,
                            index,
                            accompagnement.Users.length
                          )
                        }
                      >
                        {accompagnement.Users.length > 1 &&
                        accompagnement.AccesUser === true
                          ? "Ouvert"
                          : "Fermé"}
                      </button>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Administrator;
