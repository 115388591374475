/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import moment from "moment";
import "moment/locale/fr";
import API from "../../api";
import { urlImage } from "../../utils";
import ModalConfirm from "../../components/ModalConfirm";
import { Button, Snackbar, IconButton, Slide } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Helmet } from "react-helmet";
moment.locale("fr");

const AccompagnementDetail = () => {
  const { id } = useParams();
  const [accompagnementDetail, setAccompagnementDetail] = useState([]);
  const [user, setUser] = useState([]);
  const [open, setOpen] = useState(false);

  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

  let navigate = useNavigate();

  let snackBarMessage = "Votre demande a été envoyée à l'administratrice.";

  const handleNavigate = () => {
    navigate(`/AccompagnementsUpdate/${id}`);
  };

  function TransitionLeft(props) {
    return <Slide {...props} direction="left" />;
  }

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        OK
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const handleRequestAccompagnement = () => {
    let form = {
      title: accompagnementDetail.title,
      lastname: user.lastname,
      firstname: user.firstname,
      email: user.email,
    };
    API.post(`/requestAccompagnement`, form, {
      headers: { Authorization: `Bearer ${userInfo.token}` },
    }).then((res) => {
      handleClick();
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    API.get(`accompagnements/${id}`).then((res) => {
      setAccompagnementDetail(res.data.data);
    });
    if (userInfo) {
      API.get(`users/${userInfo.idUser}`, {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      }).then((res) => {
        setUser(res.data.data);
      });
    }
  }, []);

  return (
    <div className="center">
      <Helmet>
        <title>{accompagnementDetail.title}</title>
        <meta name="description" content={accompagnementDetail.resume} />
      </Helmet>
      <div key={accompagnementDetail.id} className="content-detail">
        <h3>{accompagnementDetail.title}</h3>
        {accompagnementDetail.image ? (
          <img
            src={urlImage + accompagnementDetail.image}
            alt="accompagnementDetail"
          />
        ) : (
          ""
        )}
        <p className="detail-description">{accompagnementDetail.description}</p>
        <ul>
          <li className="detail-duree">
            <span>Durée : </span>
            {accompagnementDetail.duree}
          </li>

          <li className="detail-price">
            <span>Prix : </span>
            {accompagnementDetail.price}
          </li>
        </ul>
        {userInfo !== null ? (
          userInfo.admin === false && (
            <button
              className="btn right detail-button"
              onClick={handleRequestAccompagnement}
            >
              <span> Faire une demande de souscription</span>
            </button>
          )
        ) : accompagnementDetail.image ? (
          <div className=".sign-btns">
            <p>
              Pour souscrire à l'accompagnement, contactez-moi via le
              formulaire.
            </p>
            <Link to={`/Contacts`} className="btn btn-sign-access">
              <span> Formulaire de contact </span>
            </Link>
          </div>
        ) : (
          <p>
            Pour prendre rendez-vous, contactez moi au :
            <span className="phone-number-span"> 06 73 50 64 73</span>{" "}
          </p>
        )}
      </div>

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={snackBarMessage}
        action={action}
        TransitionComponent={TransitionLeft}
      />

      {userInfo
        ? userInfo.admin === true && (
            <div className="button-container">
              <button className="btn btn-update" onClick={handleNavigate}>
                Modifier
              </button>
              <ModalConfirm id={id} route="accompagnements" />
            </div>
          )
        : ""}
      <button onClick={() => navigate(-1)} className="btn button-back">
        Retour à la liste
      </button>
    </div>
  );
};

export default AccompagnementDetail;
