/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import API from "../api";
import { Button, IconButton, Slide, Snackbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const AccompagnementForm = ({ isEditForm }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [resume, setResume] = useState("");
  const [duree, setDuree] = useState("");
  const [price, setPrice] = useState("");
  const [type, setType] = useState("hypnose");
  const [image, setImage] = useState({ preview: "", data: "" });
  const [podcast, setPodcast] = useState({ preview: "", data: "" });
  const [video, setVideo] = useState({ preview: "", data: "" });
  const [document, setDocument] = useState({ preview: "", data: "" });

  let navigate = useNavigate();

  const optionsType = [
    {
      label: "Hypnose",
      value: "hypnose",
    },
    {
      label: "Coaching",
      value: "therapie",
    },
    {
      label: "Groupes",
      value: "groupes",
    },
  ];

  const [open, setOpen] = useState(false);

  const { id } = useParams();

  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

  let snackBarMessage = "";
  if (isEditForm) {
    snackBarMessage = "La modification a été effectuée avec succès !";
  } else {
    snackBarMessage = "La création de l'élément a été effectuée avec succès !";
  }

  useEffect(() => {
    if (isEditForm) {
      API.get(`accompagnements/${id}`).then((res) => {
        setTitle(res.data.data.title);
        setDescription(res.data.data.description);
        setResume(res.data.data.resume);
        setDuree(res.data.data.duree);
        setPrice(res.data.data.price);
        setType(res.data.data.type);
      });
    }
  }, []);

  const handleFileChange = (e) => {
    const img = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    };

    setImage(img);
  };

  const handlePodcastChange = (e) => {
    const podcast = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    };
    setPodcast(podcast);
  };

  const handleVideoChange = (e) => {
    const video = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    };
    setVideo(video);
  };

  const handleDocumentChange = (e) => {
    const document = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    };
    setDocument(document);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("resume", resume);
    formData.append("duree", duree);
    formData.append("type", type);
    formData.append("price", price);
    formData.append("image", image.data);
    formData.append("podcast", podcast.data);
    formData.append("video", video.data);
    formData.append("document", document.data);
    if (isEditForm) {
      API.put(`accompagnements/${id}`, formData, {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      }).then((res) => {
        handleClick();
        setTimeout(() => navigate(-1), 2000);
      });
    } else {
      API.post(`accompagnements/`, formData, {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      }).then((res) => {
        handleClick();
        setTimeout(() => navigate(-1), 2000);
      });
    }
  };

  function TransitionLeft(props) {
    return <Slide {...props} direction="left" />;
  }

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        OK
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div className="container">
      {!isEditForm && (
        <div className="form-container">
          <h2>Ajout d'un accompagnement</h2>

          <form onSubmit={handleSubmit} className="contact-form">
            <label htmlFor="title" className="label-form">
              Titre :
              <input
                type="text"
                aria-label="title"
                name="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </label>

            <label htmlFor="type" className="label-form">
              Type
              <select
                className="select-option"
                value={type}
                name="type"
                id="type"
                onChange={(e) => {
                  setType(e.target.value);
                }}
              >
                {optionsType.map((option) => (
                  <option
                    value={option.value}
                    key={option.value}
                    className="option-select"
                  >
                    {option.label}
                  </option>
                ))}
              </select>
            </label>

            <label htmlFor="description" className="label-form">
              Contenu :
              <textarea
                id="description"
                name="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </label>

            <label htmlFor="resume" className="label-form">
              Résumé :
              <textarea
                id="resume"
                name="resume"
                value={resume}
                onChange={(e) => setResume(e.target.value)}
              />
            </label>

            <label htmlFor="duree" className="label-form">
              Durée :
              <input
                type="text"
                aria-label="durée"
                name="duree"
                value={duree}
                onChange={(e) => setDuree(e.target.value)}
              />
            </label>

            <label htmlFor="price" className="label-form">
              Prix :
              <input
                type="text"
                aria-label="prix"
                name="price"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
            </label>

            <select
              name="type"
              id="type"
              onChange={(e) => {
                setType(e.target.value);
              }}
            >
              <option value="individuels">Individuels</option>
              <option value="groupes">Groupes</option>
            </select>

            <label htmlFor="image" className="custom-file-upload">
              {image?.data?.name?.length > 0 ? image.data.name : "Image"}
              {image?.data?.name?.length > 0 && (
                <button
                  className="btn button-remove"
                  onClick={() => setImage({ preview: "", data: "" })}
                >
                  Retirer
                </button>
              )}
              <input
                type="file"
                aria-label="image"
                id="image"
                name="image"
                onChange={handleFileChange}
              />
            </label>

            <label htmlFor="podcast" className="custom-file-upload">
              {podcast?.data?.name?.length > 0 ? podcast.data.name : "Podcast"}
              {podcast?.data?.name?.length > 0 && (
                <button
                  className="btn button-remove"
                  onClick={() => setPodcast({ preview: "", data: "" })}
                >
                  Retirer
                </button>
              )}
              <input
                type="file"
                aria-label="podcast"
                id="podcast"
                name="podcast"
                onChange={handlePodcastChange}
              />
            </label>

            <label htmlFor="video" className="custom-file-upload">
              {video?.data?.name?.length > 0 ? video.data.name : "Video"}
              {video?.data?.name?.length > 0 && (
                <button
                  className="btn button-remove"
                  onClick={() => setVideo({ preview: "", data: "" })}
                >
                  Retirer
                </button>
              )}
              <input
                type="file"
                aria-label="vidéo"
                id="video"
                name="video"
                onChange={handleVideoChange}
              />
            </label>

            <label htmlFor="document" className="custom-file-upload">
              {document?.data?.name?.length > 0
                ? document.data.name
                : "Document"}
              {document?.data?.name?.length > 0 && (
                <button
                  className="btn button-remove"
                  onClick={() => setDocument({ preview: "", data: "" })}
                >
                  Retirer
                </button>
              )}
              <input
                type="file"
                aria-label="document"
                id="document"
                name="document"
                onChange={handleDocumentChange}
              />
            </label>

            <input
              type="submit"
              aria-label="envoyer"
              value="Envoyer"
              className="btn right button-submit"
            />
          </form>
        </div>
      )}
      {isEditForm && (
        <div className="form-container">
          <h2>Modification</h2>

          <form onSubmit={handleSubmit} className="contact-form">
            <label htmlFor="title" className="label-form">
              Titre :
              <input
                type="text"
                name="title"
                aria-label="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </label>

            <label htmlFor="type" className="label-form">
              Type
              <select
                className="select-option"
                value={type}
                name="type"
                id="type"
                onChange={(e) => {
                  setType(e.target.value);
                }}
              >
                {optionsType.map((option) => (
                  <option
                    value={option.value}
                    key={option.value}
                    className="option-select"
                  >
                    {option.label}
                  </option>
                ))}
              </select>
            </label>

            <label htmlFor="description" className="label-form">
              Contenu :
              <textarea
                id="description"
                name="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </label>

            <label htmlFor="resume" className="label-form">
              Résumé :
              <textarea
                id="resume"
                name="resume"
                value={resume}
                onChange={(e) => setResume(e.target.value)}
              />
            </label>

            <label htmlFor="duree" className="label-form">
              Durée (en minutes) :
              <input
                type="text"
                name="duree"
                aria-label="durée"
                value={duree}
                onChange={(e) => setDuree(e.target.value)}
              />
            </label>

            <label htmlFor="price" className="label-form">
              Prix (en euros) :
              <input
                type="text"
                name="price"
                aria-label="prix"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
            </label>

            <label htmlFor="image" className="custom-file-upload">
              {image?.data?.name?.length > 0 ? image.data.name : "Image"}
              {image?.data?.name?.length > 0 && (
                <button
                  className="btn button-remove"
                  onClick={() => setImage({ preview: "", data: "" })}
                >
                  Retirer
                </button>
              )}
              <input
                type="file"
                id="image"
                aria-label="vidéo"
                name="image"
                onChange={handleFileChange}
              />
            </label>

            <label htmlFor="podcast" className="custom-file-upload">
              {podcast?.data?.name?.length > 0 ? podcast.data.name : "Podcast"}
              {podcast?.data?.name?.length > 0 && (
                <button
                  className="btn button-remove"
                  onClick={() => setPodcast({ preview: "", data: "" })}
                >
                  Retirer
                </button>
              )}
              <input
                type="file"
                id="podcast"
                aria-label="podcast"
                name="podcast"
                onChange={handlePodcastChange}
              />
            </label>

            <label htmlFor="video" className="custom-file-upload">
              {video?.data?.name?.length > 0 ? video.data.name : "Video"}
              {video?.data?.name?.length > 0 && (
                <button
                  className="btn button-remove"
                  onClick={() => setVideo({ preview: "", data: "" })}
                >
                  Retirer
                </button>
              )}
              <input
                type="file"
                id="video"
                aria-label="vidéo"
                name="video"
                onChange={handleVideoChange}
              />
            </label>

            <label htmlFor="document" className="custom-file-upload">
              {document?.data?.name?.length > 0
                ? document.data.name
                : "Document"}
              {document?.data?.name?.length > 0 && (
                <button
                  className="btn button-remove"
                  onClick={() => setDocument({ preview: "", data: "" })}
                >
                  Retirer
                </button>
              )}
              <input
                type="file"
                aria-label="document"
                id="document"
                name="document"
                onChange={handleDocumentChange}
              />
            </label>

            <input
              type="submit"
              aria-label="envoyer"
              value="Envoyer"
              className="btn right button-submit"
            />
          </form>
        </div>
      )}
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={snackBarMessage}
        action={action}
        TransitionComponent={TransitionLeft}
      />
      <button onClick={() => window.history.back()} className="btn button-back">
        Retour à la liste
      </button>
    </div>
  );
};

export default AccompagnementForm;
