import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import React from "react";
import API from "../api";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Slide } from "@mui/material";

const ModalConfirm = (props) => {
  const [open, setOpen] = React.useState(false);

  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        OK
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const handleSubmit = () => {
    confirmAlert({
      title: "Confirmation de suppression",
      message: "Voulez vous supprimer définitivement cet élément ?",
      buttons: [
        {
          label: "Oui",
          onClick: () => handleDelete(),
        },
        {
          label: "Non",
          onClick: () => alert("Vous avez refusé la suppression"),
        },
      ],
    });
  };

  const handleDelete = async () => {
    await API.delete(`${props.route}/${+props.id}`, {
      headers: { Authorization: `Bearer ${userInfo.token}` },
    }).then(() => {
      handleClick();
    });
    setTimeout(() => {
      window.location.assign(`/${props.route}`);
    }, 2000);
  };

  function TransitionLeft(props) {
    return <Slide {...props} direction="left" />;
  }

  return (
    <>
      <button className="btn btn-delete" onClick={handleSubmit}>
        Supprimer
      </button>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message="L'élément a bien été supprimé"
        action={action}
        TransitionComponent={TransitionLeft}
      />
    </>
  );
};

export default ModalConfirm;
