import React, { useState } from "react";
import clsx from "clsx";
import { useContactFormValidator } from "../hooks/UseContactFormValidator";
import API from "../api";
import { Helmet } from "react-helmet";

const Login = () => {
  const [form, setForm] = useState({
    email: "",
    password: "",
  });
  const [isSend, setIsSend] = useState(false);
  const [errorLogin, setErrorLogin] = useState("");

  const { errors, validateForm, onBlurField } = useContactFormValidator(form);

  const onUpdateField = (e) => {
    setIsSend(true);

    const field = e.target.name;
    const nextFormState = {
      ...form,
      [field]: e.target.value,
    };
    setForm(nextFormState);
    if (errors[field].dirty)
      validateForm({
        form: nextFormState,
        errors,
        field,
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { isValid } = validateForm({ form, errors, forceTouchErrors: true });
    if (!isValid) {
      alert("Le mot de passe ou l'email n'est pas valide");
    }
    API.post(`login`, form)
      .then((res) => {
        const userInfo = {
          admin: res.data.data.admin,
          idUser: res.data.data.id,
          token: res.data.token,
        };
        sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
        window.location.assign("/");
      })
      .catch((error) => {
        setErrorLogin(error.response.data.message);
        setForm({ email: "", password: "" });
      });
  };

  return (
    <div className="form-container">
      <Helmet>
        <title>S'authentifier</title>
        <meta name="description" content="s'authentifier" />
      </Helmet>
      <h2 className="center">S'authentifier</h2>
      <form onSubmit={handleSubmit} className="contact-form">
        {errorLogin !== " " && (
          <div>
            {" "}
            <span className="formFieldErrorMessage">{errorLogin}</span>
          </div>
        )}
        <label className="label-form">
          Identifiant
          <input
            type="email"
            aria-label="email"
            name="email"
            value={form.email}
            className={clsx(
              errors.email.dirty && errors.email.error && "formFieldError"
            )}
            onChange={onUpdateField}
            onBlur={onBlurField}
          />{" "}
        </label>
        {errors.email.dirty && errors.email.error ? (
          <p className="formFieldErrorMessage">{errors.email.message}</p>
        ) : null}
        <label className="label-form">
          Mot de passe
          <input
            type="password"
            aria-label="mot de passe"
            name="password"
            value={form.password}
            className={clsx(
              errors.password.dirty && errors.password.error && "formFieldError"
            )}
            onChange={onUpdateField}
            onBlur={onBlurField}
          />
        </label>
        {errors.password.dirty && errors.password.error ? (
          <p className="formFieldErrorMessage">{errors.password.message}</p>
        ) : null}
        {isSend && (
          <input
            type="submit"
            aria-label="envoyer"
            value="Soumettre"
            className="btn right btn-send"
          />
        )}
      </form>
      {/* <div className="sign-btns">
        <span>Pas encore membre ?</span>
        <NavLink to="/Signin">
          <button className="btn-signin">S'inscrire</button>
        </NavLink>
      </div> */}
      {/* <div>
        <span>Mot de passe oublié ?</span>
        <NavLink to="/RequestPassword">
          <span className="btn-signin">Cliquez ici</span>
        </NavLink>
      </div> */}
    </div>
  );
};

export default Login;
