import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import API from "../api";
import moment from "moment";
import "moment/locale/fr";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import { urlImage } from "../utils";
moment.locale("fr");

const GroupePage = () => {
  const [groupe, setGroupe] = useState([]);

  const userInfo = JSON.parse(`${sessionStorage.getItem("userInfo")}`);

  useEffect(() => {
    API.get(`accompagnements`).then((res) => {
      const therapieFilter = res.data.data
        .filter((item) => item.type === "groupes")
        .sort(function (a, b) {
          return new Date(b.created) + new Date(a.created);
        });
      setGroupe(therapieFilter);
    });
  }, []);

  return (
    <div style={{ marginBottom: 16 }}>
      <Helmet>
        <title>Spiritualité Angers</title>
        <meta name="description" content="Groupe Angers" />
      </Helmet>

      <h2 className="center">Spiritualité</h2>
      <div className="picture-accompagnement">
        <img src="flower-lotus.jpg" alt="atelier groupe" />
      </div>
      <p className="presentation-text-accompagnement">
        Ces séances s'adressent uniquement aux adultes. <br /> Elles peuvent
        avoir lieu en présentiel ou en distanciel.
      </p>
      <p className="presentation-text-accompagnement">
        Ces séances permettent :
        <ul>
          <li>
            de prendre conscience de schémas récurrents et de s'en libérer
          </li>
          <li>
            de comprendre les enjeux auxquels vous faites face dans votre vie
            actuelle
          </li>
          <li>de vous connecter à votre chemin de réalisation</li>
          <li>de vous libérer de vos blocages émotionnels</li>
          <li>de recevoir des messages, des conseils, des éclairages</li>
          <li>d'explorer vos capacités, votre potentiel</li>
          <li>de passer à l'action avec sérénité</li>
        </ul>
      </p>
      <p className="presentation-text-accompagnement">
        Vous souhaitez davantage de détails sur mes accompagnements ? N'hésitez
        pas à me contacter.
      </p>

      {groupe.length < 1 ? (
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      ) : (
        <div className="card-container">
          {groupe.map((item) => {
            return (
              <div key={item.id} className="card-content">
                <h3>{item.title}</h3>

                {item.image ? (
                  <img src={urlImage + item.image} alt="accompagnement" />
                ) : (
                  <img src="../default.jpg" alt="accompagnement" />
                )}

                <p className="card-description">{item.resume}</p>
                <ul>
                  <li>Durée : {item.duree}</li>

                  <li>Prix : {item.price}</li>
                </ul>

                <Link
                  to={`/accompagnements/${item.id}`}
                  className="btn right card-button"
                >
                  <span>Plus de détails</span>
                </Link>
              </div>
            );
          })}
        </div>
      )}

      {userInfo
        ? userInfo.admin === true && (
            <div className="container-new">
              <NavLink to="/AccompagnementsAdd">
                <button className="btn btn-new">
                  Ajouter un accompagnement
                </button>
              </NavLink>
            </div>
          )
        : ""}
    </div>
  );
};

export default GroupePage;
