import React, { useState, useRef, useEffect } from "react";
import { NavLink } from "react-router-dom";
import DropdownLink from "./DropdownLink";

const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navRef = useRef(null);
  const toggleNav = (e) => {
    setIsOpen(!isOpen);
  };

  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleLogOut = () => {
    sessionStorage.removeItem("userInfo");
    window.location.assign("/");
  };

  const handleClickOutside = (event) => {
    // Vérifie si le clic est en dehors du conteneur du menu
    if (navRef.current && !navRef.current.contains(event.target)) {
      handleClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      // Ajouter l'écouteur d'événements lorsque le menu est ouvert
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      // Supprimer l'écouteur d'événements lorsque le menu est fermé
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Nettoyer l'écouteur lors du démontage du composant
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <nav ref={navRef}>
      <NavLink
        to="/"
        className="nav-icon"
        aria-label="visit homepage"
        arria-current="page"
      >
        <img src="blue-logo.png" alt="logo" />
        <span>Marianne Derouet</span>
      </NavLink>
      <div className="main-navlinks">
        <button
          className={isOpen ? "hamburger open" : "hamburger"}
          type="button"
          aria-label="Toggle navigation"
          aria-expanded={isOpen ? "true" : "false"}
          onClick={toggleNav}
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
        <div
          className={
            isOpen ? "navlinks-container openit" : "navlinks-container"
          }
        >
          <NavLink
            to="/"
            className={(navData) => (navData.isActive ? "navActive" : "none")}
            onClick={handleClose}
          >
            <span> Accueil</span>
          </NavLink>
          <NavLink
            to="/Quisuisje"
            className={(navData) => (navData.isActive ? "navActive" : "none")}
            onClick={handleClose}
          >
            <span>Qui suis-je?</span>
          </NavLink>

          <NavLink
            to="/Hypnose"
            className={(navData) => (navData.isActive ? "navActive" : "none")}
            onClick={handleClose}
          >
            <span>Hypnose</span>
          </NavLink>

          <NavLink
            to="/Therapie-Coaching"
            className={(navData) => (navData.isActive ? "navActive" : "none")}
            onClick={handleClose}
          >
            <span>Coaching / Thérapie</span>
          </NavLink>

          <NavLink
            to="/Groupes"
            className={(navData) => (navData.isActive ? "navActive" : "none")}
            onClick={handleClose}
          >
            <span>Spiritualité</span>
          </NavLink>

          <NavLink
            to="/Articles"
            className={(navData) => (navData.isActive ? "navActive" : "none")}
            onClick={handleClose}
          >
            <span>Blog</span>
          </NavLink>

          <NavLink
            to="/Testimonials"
            className={(navData) => (navData.isActive ? "navActive" : "none")}
            onClick={handleClose}
          >
            <span>Témoignages</span>
          </NavLink>

          <NavLink
            to="/Contacts"
            className={(navData) => (navData.isActive ? "navActive" : "none")}
            onClick={handleClose}
          >
            <span>Contact</span>
          </NavLink>

          <DropdownLink />
        </div>
      </div>

      {userInfo === null && (
        <div className="nav-authentication">
          <NavLink to="/Login" className="user-sign">
            <i className="fas fa-user"></i>
          </NavLink>

          <div className="sign-btns">
            <NavLink to="/Login">
              <button className="btn-login">Administrateur</button>
            </NavLink>
          </div>
        </div>
      )}
      {userInfo && (
        <div className="nav-authentication">
          {" "}
          <NavLink to="/" className="user-sign">
            <i className="fas fa-sign-out-alt" onClick={handleLogOut}></i>
          </NavLink>
          <div className="sign-btns">
            <button className="btn-logout" onClick={handleLogOut}>
              Se déconnecter
            </button>{" "}
          </div>{" "}
        </div>
      )}
    </nav>
  );
};

export default Navigation;
