import React, { useEffect, useState } from "react";
import moment from "moment";
import "moment/locale/fr";
import ModalConfirm from "../components/ModalConfirm";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { urlImage } from "../utils";
import API from "../api";
import { Helmet } from "react-helmet";
moment.locale("fr");

const Testimonial = () => {
  const [testimonialList, setTestimonialList] = useState([]);

  let navigate = useNavigate();

  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

  const handleNavigate = (id) => {
    navigate(`/TestimonialsUpdate/${id}`);
  };

  useEffect(() => {
    API.get(`testimonials`).then((res) => {
      setTestimonialList(res.data.data);
    });
  }, []);

  return (
    <div className="testimonial-parent">
      <Helmet>
        <title>Explorez les témoignages</title>
        <meta name="description" content="témoignages sur Marianne Derouet" />
      </Helmet>
      <h2 className="center">Témoignages</h2>
      <p className="sub-title">Ils en parlent mieux que moi</p>

      {testimonialList.length < 1 ? (
        <div class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      ) : (
        testimonialList
          .sort(function (a, b) {
            return new Date(b.created) - new Date(a.created);
          })
          .map((testimonial) => {
            return (
              <div key={testimonial.id} className="testimonial-container">
                <h3>{testimonial.title}</h3>
                <p className="testimonial-description">
                  {testimonial.description}
                </p>{" "}
                {testimonial.image ? (
                  <img src={urlImage + testimonial.image} alt="temoignage" />
                ) : (
                  ""
                )}
                <p className="testimonial-created">
                  {testimonial.author},<br />
                  {moment(testimonial.created).format("LLLL")}
                </p>
                {userInfo
                  ? userInfo.admin === true && (
                      <div className="button-container">
                        <button
                          className="btn btn-update"
                          onClick={() => handleNavigate(testimonial.id)}
                        >
                          Modifier
                        </button>
                        <ModalConfirm
                          id={testimonial.id}
                          route="testimonials"
                        />
                      </div>
                    )
                  : ""}
              </div>
            );
          })
      )}

      {userInfo
        ? userInfo.admin === true && (
            <p className="container-new">
              <NavLink to="/TestimonialsAdd">
                <button className="btn btn-new">Ajouter un témoignage</button>
              </NavLink>
            </p>
          )
        : ""}
    </div>
  );
};

export default Testimonial;
