import React, { useState } from "react";

import DropDownButton from "./../components/DropDownButton";

const Home = () => {
  const [showCode, setShowCode] = useState(false);

  const showQrcode = () => {
    setShowCode(!showCode);
  };

  return (
    <div className="container-home">
      <img className="img-home" src="home-img.jpg" alt="visuel abc talk" />
      <p
        style={{
          paddingLeft: "10px",
          paddingRight: "10px",
          textAlign: "center",
        }}
      >
        Pour visualiser mon émission Index ABC talk :{" "}
        <a href="https://www.indexabc.fr/pro/marianne-derouet" target="_blank">
          Cliquez ici
        </a>
      </p>
      <div className="home-description">
        <div className="home-paragraphe-citation">
          <p className="citation">
            {" "}
            <q>Il n’y a pas de hasard, il n’y a que des rendez-vous. </q> <br />
            Paul Eluard
          </p>
        </div>

        <div className="home-paragraphe">
          <p className="presentation-text-home">
            Je suis accompagnante pour les adultes, les adolescents, les enfants
            et les groupes. Je vous soutiens dans votre cheminement et vous
            accompagne avec bienveillance et écoute. Je vous conseille et vous
            transmets des clés qui vous permettront de découvrir les ressources
            qui sont en vous pour avancer avec{" "}
            <strong>confiance, sérénité et audace</strong>.
          </p>
          <p className="presentation-text-home">
            J’utilise des pratiques auxquelles je me suis formée (lecture de
            mémoires akashiques, hypnose, Techniques de libération émotionnelle
            (EFT), guidance, thérapie narrative, coaching en image) afin de vous
            proposer{" "}
            <strong>un accompagnement « sur-mesure » et novateur</strong> .
          </p>
          <p className="presentation-text-home">
            Je pense que <strong>tout est possible</strong> , tout est
            réalisable, <strong>on peut tout créer </strong> car nous sommes les
            créateurs de notre vie. Je souhaite vous aider à
            <strong> vous épanouir</strong>, vous connecter à{" "}
            <strong>votre potentiel</strong>, voilà ce qui m'anime et me donne
            de l'élan.
          </p>
        </div>
      </div>
      <DropDownButton />

      {showCode ? (
        <img
          onClick={showQrcode}
          className="img-qrcode"
          src="qrcode.jpg"
          alt="qr code"
        />
      ) : (
        <button className="qr-btn" onClick={showQrcode}>
          Afficher le QR Code
        </button>
      )}
    </div>
  );
};

export default Home;
