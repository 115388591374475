/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import API from "../api";
import { Button, IconButton, Slide, Snackbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ArticleForm = ({ isEditForm }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [resume, setResume] = useState("");
  const [image, setImage] = useState({ preview: "", data: "" });
  const [open, setOpen] = useState(false);

  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

  const { id } = useParams();

  let navigate = useNavigate();

  let snackBarMessage = "";
  if (isEditForm) {
    snackBarMessage = "La modification a été effectuée avec succès !";
  } else {
    snackBarMessage = "La création de l'élément a été effectuée avec succès !";
  }

  useEffect(() => {
    if (isEditForm) {
      API.get(`articles/${id}`).then((res) => {
        setTitle(res.data.data.title);
        setDescription(res.data.data.description);
        setResume(res.data.data.resume);
      });
    }
  }, []);

  const handleFileChange = (e) => {
    const img = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    };
    setImage(img);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("resume", resume);
    formData.append("image", image.data);
    handleClick();
    if (isEditForm) {
      API.put(`articles/${id}`, formData, {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      }).then((res) => {});
    } else {
      API.post(`articles/`, formData, {
        headers: { Authorization: `Bearer ${userInfo.token}` },
      }).then((res) => {});
    }
    setTimeout(() => {
      window.location.assign("/articles");
    }, 2000);
  };

  function TransitionLeft(props) {
    return <Slide {...props} direction="left" />;
  }

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        OK
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div className="container">
      {!isEditForm && (
        <div className="form-container">
          <h2>Ajout d'un article</h2>

          <form onSubmit={handleSubmit} className="contact-form">
            <label htmlFor="title" className="label-form">
              Titre :
              <input
                type="text"
                aria-label="titre"
                name="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </label>
            <label htmlFor="description" className="label-form">
              Contenu :
              <textarea
                id="description"
                name="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </label>

            <label htmlFor="resume" className="label-form">
              Résumé :
              <textarea
                id="resume"
                name="resume"
                value={resume}
                onChange={(e) => setResume(e.target.value)}
              />
            </label>
            <label htmlFor="image" className="custom-file-upload">
              {image?.data?.name?.length > 0 ? image.data.name : "Image"}
              {image?.data?.name?.length > 0 && (
                <button
                  className="btn button-remove"
                  onClick={() => setImage({ preview: "", data: "" })}
                >
                  Retirer
                </button>
              )}
              <input
                type="file"
                aria-label="image"
                id="image"
                name="image"
                onChange={handleFileChange}
              />
            </label>
            <input
              type="submit"
              aria-label="envoyer"
              value="Envoyer"
              className="btn right button-submit"
            />
          </form>
        </div>
      )}
      {isEditForm && (
        <div className="form-container">
          <h2>Modification</h2>

          <form onSubmit={handleSubmit} className="contact-form">
            <label htmlFor="title" className="label-form">
              Titre :
              <input
                type="text"
                name="title"
                aria-label="titre"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </label>
            <label htmlFor="description" className="label-form">
              Contenu :
              <textarea
                id="description"
                name="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </label>

            <label htmlFor="resume" className="label-form">
              Résumé :
              <textarea
                id="resume"
                name="resume"
                value={resume}
                onChange={(e) => setResume(e.target.value)}
              />
            </label>
            <label htmlFor="image" className="custom-file-upload">
              {image?.data?.name?.length > 0 ? image.data.name : "Image"}
              {image?.data?.name?.length > 0 && (
                <button
                  className="btn button-remove"
                  onClick={() => setImage({ preview: "", data: "" })}
                >
                  Retirer
                </button>
              )}
              <input
                type="file"
                id="image"
                aria-label="image"
                name="image"
                onChange={handleFileChange}
              />
            </label>
            <input
              type="submit"
              value="Envoyer"
              aria-label="envoyer"
              className="btn right button-submit"
            />
          </form>
        </div>
      )}
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={snackBarMessage}
        action={action}
        TransitionComponent={TransitionLeft}
      />
      <button onClick={() => navigate("/articles")} className="btn button-back">
        Retour à la liste
      </button>
    </div>
  );
};

export default ArticleForm;
