import React from "react";

const Header = () => {
  return (
    <>
      <div className="header-container">
        <div className="bubble-div">
          <span className="dot"></span>
        </div>
        <div className="bubble-div">
          <span className="dot"></span>
        </div>
        <div className="bubble-div">
          <span className="dot"></span>
        </div>
        <div className="bubble-div">
          <span className="dot"></span>
        </div>
        <div className="bubble-div">
          <span className="dot"></span>
        </div>
        <div className="bubble-div">
          <span className="dot"></span>
        </div>
        <div className="bubble-div">
          <span className="dot"></span>
        </div>
        <div className="bubble-div">
          <span className="dot"></span>
        </div>
        <div className="bubble-div">
          <span className="dot"></span>
        </div>
        <div className="bubble-div">
          <span className="dot"></span>
        </div>
        <div className="bubble-div">
          <span className="dot"></span>
        </div>
        <div className="bubble-div">
          <span className="dot"></span>
        </div>
        <div className="bubble-div">
          <span className="dot"></span>
        </div>
        <div className="bubble-div">
          <span className="dot"></span>
        </div>
        <div className="bubble-div">
          <span className="dot"></span>
        </div>
        <div className="header-title">
          <img src="Logo-yellow.png" alt="logo" className="img-logo" />
          <h1 className="center">
            Marianne Derouet
            <p>
              Accompagnements individuels <br />
              Formations <br />
              Conférences
            </p>
          </h1>
          <div className="img-header-container">
            <img src="header-photo.jpg" alt="profil" className="img-bandeau" />
          </div>
        </div>
        <div className="header-social-network">
          <a
            title="lien vers le facebook de Marianne Derouet"
            href="https://instagram.com/le.coin.qui.fait.du.bien?igshid=YmMyMTA2M2Y="
          >
            {" "}
            <i className="fab fa-instagram"></i>
          </a>
          <a
            title="lien vers l'instagram de Marianne Derouet"
            href="https://m.facebook.com/marianne.ribot?refsrc=deprecated&_rdr"
          >
            <i className="fab fa-facebook"></i>
          </a>
          <a
            title="lien vers le linkedin de Marianne Derouet"
            href="https://www.linkedin.com/in/marianne-derouet-9a3b011b2/"
          >
            <i className="fab fa-linkedin"></i>
          </a>
        </div>
      </div>
      <div className="divider-header"></div>
    </>
  );
};

export default Header;
