import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import "moment/locale/fr";
import { urlImage } from "../../utils";
import API from "../../api";
import ModalConfirm from "../../components/ModalConfirm";
moment.locale("fr");

const ArticleDetail = () => {
  const { id } = useParams();
  const [actuDetail, setActuDetail] = useState([]);

  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

  let navigate = useNavigate();

  useEffect(() => {
    API.get(`articles/${id}`).then((res) => {
      setActuDetail(res.data.data);
    });
  }, [id]);

  const handleNavigate = () => {
    navigate(`/ActusUpdate/${id}`);
  };

  return (
    <div className="container">
      <div key={actuDetail.id} className="content-detail">
        <h3>{actuDetail.title}</h3>

        {actuDetail.image ? (
          <img src={urlImage + actuDetail.image} alt="actuDetail" />
        ) : (
          ""
        )}

        <p className="detail-description">{actuDetail.description}</p>
        <span className="detail-date">
          {moment(actuDetail.created).format("LLLL")}{" "}
        </span>
      </div>
      {userInfo
        ? userInfo.admin === true && (
            <div className="button-container">
              <button className="btn btn-update" onClick={handleNavigate}>
                Modifier
              </button>
              <ModalConfirm id={id} route="articles" />
            </div>
          )
        : ""}
      <button onClick={() => navigate("/articles")} className="btn button-back">
        Retour à la liste
      </button>
    </div>
  );
};

export default ArticleDetail;
