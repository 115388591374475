import React from "react";
import { Helmet } from "react-helmet";

const WhoIAm = () => {
  return (
    <div>
      <Helmet>
        <title>Mon parcours</title>
        <meta name="description" content="Découvrir Marianne Derouet" />
      </Helmet>
      <h2 className="center"> Qui suis-je?</h2>
      <div className="container-presentation">
        <div className="container-present-image">
          <img src="profil.jpg" alt="profil" />
          <p>
            Sensible et empathique, j’ai toujours été curieuse des{" "}
            <strong>liens qui unissent les êtres humains</strong>, captivée par
            <strong> la Magie de la Vie</strong> et persuadée que
            <strong> le hasard n’existe pas</strong> .
          </p>
        </div>

        <p className="presentation-text">
          J’ai cheminé, portée par mes désirs d’apprendre, de me découvrir, de
          <strong> mettre du sens dans mon existence</strong> et de{" "}
          <strong>créer la vie qui me ressemble</strong> .
        </p>

        <p className="presentation-text">
          De stages en formations, de conférences en ateliers de développement
          personnel, je me suis redécouverte et j’ai fait des{" "}
          <strong>voyages intérieurs transformateurs</strong> . J’ai appris à
          m’aimer, à me faire confiance et j’ai trouvé l’énergie d’avancer sur
          mon chemin, <strong> avec joie et dans la gratitude</strong>.
        </p>

        <p className="presentation-text">
          Après vingt années passées dans l’Education Nationale (16 ans de
          professorat des écoles, un an d’enseignante référente et 3 ans de
          direction d’un dispositif d’enseignement adapté avec management de
          personnel d’encadrement),{" "}
          <strong> j’ai décidé de changer de cap</strong>.
        </p>

        <p className="presentation-text">
          Forte de ma belle expérience dans l’enseignement et la direction, je
          porte en moi <strong>le goût de la transmission</strong> et{" "}
          <strong> une appétence profonde pour l’Humain</strong>.
        </p>

        <p className="presentation-middleTitle">
          Aujourd’hui, j'ai créé mon <strong>cabinet d'accompagnement </strong>
          et je vous reçois en présentiel à Trélazé ou en distanciel.
        </p>

        <p className="presentation-text">
          Comme je ne pense pas qu’il faille prendre « notre mal en patience »
          mais « notre bien en urgence », j’ai à cœur de vous accompagner dans
          cette voie qu’est le bien-être,{" "}
          <strong> le rayonnement, la concrétisation de projets</strong> et{" "}
          <strong>l’amour de soi</strong>.
        </p>

        <p className="presentation-citation">
          Christiane Singer disait : « il fait beaucoup pour le monde, celui qui
          panse ses blessures ».
        </p>

        <p className="presentation-text">
          Cette phrase a été un déclic pour moi et m'a fait prendre conscience
          que <strong>prendre soin de soi est une priorité</strong>, pour soi,
          pour les autres, et pour le Monde.
        </p>

        <p className="presentation-text">
          Je sais que « <strong>mon pourquoi</strong> » est de vous aider à
          panser vos blessures, à vous sentir enfin épanoui, libéré et heureux.
        </p>
      </div>
    </div>
  );
};

export default WhoIAm;
