import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import API from "../api";
import moment from "moment";
import "moment/locale/fr";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import { urlImage } from "../utils";
moment.locale("fr");

const HypnosePage = () => {
  const [hypnose, setHypnose] = useState([]);

  const userInfo = JSON.parse(`${sessionStorage.getItem("userInfo")}`);

  useEffect(() => {
    API.get(`accompagnements`).then((res) => {
      const hypnoseFilter = res.data.data
        .filter((item: { type: string }) => item.type === "hypnose")
        .sort(function (a: { created: Date }, b: { created: Date }) {
          return new Date(b.created).valueOf() - new Date(a.created).valueOf();
        });
      setHypnose(hypnoseFilter);
    });
  }, []);

  return (
    <div style={{ marginBottom: 16 }}>
      <Helmet>
        <title>Hypnose Angers</title>
        <meta name="description" content="Hypnose Angers" />
      </Helmet>

      <h2 className="center">Hypnose</h2>
      <div className="picture-accompagnement">
        <img src="hypnose.webp" alt="hypnose" />
      </div>
      <p className="presentation-text-accompagnement">
        L'hypnose est un moyen naturel qui permet d'accéder à{" "}
        <strong>un état de conscience modifié</strong>.
      </p>
      <p className="presentation-text-accompagnement">
        Cette pratique permet d'accéder à l'inconscient, c'est ainsi qu'il est
        possible de{" "}
        <strong>
          {" "}
          modifier des habitudes, des schémas répétitifs, de se libérer de
          blocages, d'émotions coincées ou encore d'apaiser des tensions
        </strong>{" "}
        ou des situations difficiles.
      </p>
      <p className="presentation-text-accompagnement">
        L'hypnose est un outil qui peut être pratiqué{" "}
        <strong>dès l'enfance</strong> .
      </p>
      <p className="presentation-text-accompagnement">
        Les séances apportent une <strong>profonde relaxation</strong> et un
        sentiment de légèreté, elles vous aident à vous libérer de
        problématiques comme :
        <ul>
          <li>les peurs, les angoisses, les phobies</li>
          <li>le stress, l'anxiété</li>
          <li>les addictions (tabac, alcool, jeux, dépendance affective)</li>
          <li>les insomnies</li>
          <li>le surpoids, les troubles alimentaires</li>
          <li>les allergies</li>
          <li>les douleurs chroniques</li>
          <li>et bien d'autres …</li>
        </ul>
      </p>
      <p className="presentation-text-accompagnement">
        L'hypnose vous apporte aussi la possibilité de{" "}
        <strong>gagner en confiance</strong> , en estime et procure un
        <strong> profond bien-être</strong>.
      </p>
      <p className="center">
        <em className="description-italic">
          À noter : la pratique de l'hypnose ne remplace pas un traitement
          médical ni l'avis d'un médecin qui est le seul à pouvoir émettre un
          diagnostic et qui est habilité à prescrire ou supprimer un traitement
          médical.
        </em>
      </p>

      {hypnose.length < 1 ? (
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      ) : (
        <div className="card-container">
          {hypnose.map((item: any) => {
            return (
              <div key={item.id} className="card-content">
                <h3>{item.title}</h3>

                {item.image ? (
                  <img src={urlImage + item.image} alt="accompagnement" />
                ) : (
                  <img src="../default.jpg" alt="accompagnement" />
                )}

                <p className="card-description">{item.resume}</p>
                <ul>
                  <li>Durée : {item.duree}</li>

                  <li>Prix : {item.price}</li>
                </ul>

                <Link
                  to={`/accompagnements/${item.id}`}
                  className="btn right card-button"
                >
                  <span>Plus de détails</span>
                </Link>
              </div>
            );
          })}
        </div>
      )}

      {userInfo
        ? userInfo.admin === true && (
            <div className="container-new">
              <NavLink to="/AccompagnementsAdd">
                <button className="btn btn-new">
                  Ajouter un accompagnement
                </button>
              </NavLink>
            </div>
          )
        : ""}
    </div>
  );
};

export default HypnosePage;
