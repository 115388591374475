import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import "moment/locale/fr";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import API from "../../api";
import { urlImage } from "../../utils";
import { Helmet } from "react-helmet";
moment.locale("fr");

const Articles = () => {
  const [actuList, setActuList] = useState([]);
  let navigate = useNavigate();

  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

  useEffect(() => {
    API.get(`articles`).then((res) => {
      setActuList(res.data.data);
    });
  }, []);

  return (
    <div>
      <Helmet>
        <title>Articles et actualité</title>
        <meta
          name="description"
          content="Explorez les actualités et événements Marianne Derouet"
        />
      </Helmet>
      <h2 className="center">Les dernières nouveautés</h2>
      {actuList.length < 1 ? (
        <div class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      ) : (
        <div className="card-container">
          {actuList
            .sort(function (a, b) {
              return new Date(b.created) - new Date(a.created);
            })
            .map((actu) => {
              return (
                <div
                  key={actu.id}
                  className="card-content"
                  onClick={() => navigate(`/articles/${actu.id}`)}
                >
                  <h3>{actu.title}</h3>
                  <img
                    src={urlImage + actu.image}
                    alt="article"
                    style={{ marginBottom: "10px" }}
                  />
                  <div className="card-description">
                    <p>{actu.resume}</p>
                  </div>
                  <p className="card-date">
                    {moment(actu.created).format("LLLL")}
                  </p>
                  <Link
                    to={`/articles/${actu.id}`}
                    className="btn right card-button"
                  >
                    <span>Plus de détails</span>
                  </Link>
                </div>
              );
            })}
        </div>
      )}
      {userInfo
        ? userInfo.admin === true && (
            <div className="container-new">
              <NavLink to="/ActusAdd">
                <button className="btn btn-new">Ajouter un article</button>
              </NavLink>
            </div>
          )
        : ""}
    </div>
  );
};

export default Articles;
