import React from "react";
import { Helmet } from "react-helmet";

import DropDownButton from "./../components/DropDownButton";

const Contact = () => {
  return (
    <div className="form-container">
      <Helmet>
        <title>Contact</title>
        <meta name="description" content="Contact" />
      </Helmet>
      <h2 className="center">Contact</h2>
      <div className="contact-container">
        <p className="presentation-text-home">
          Pour toutes questions ou autres demandes, n'hésitez pas à me contacter
          par téléphone (06 73 50 64 73), ou par mail
          (mariannederouet@gmail.com). Vous pouvez également prendre rendez-vous
          :
        </p>
        <DropDownButton />
        <img className="photo-contact" src="photo-contact.JPG" alt="contact" />
        <h3 style={{ marginBottom: "8px", marginTop: "44px" }}>
          Adresse des séances en présentiel (Sur rendez-vous)
        </h3>
        <p className="presentation-text-home">
          1 rue Pierre Nion 49800 Trélazé
        </p>
        <div className="footer-content">
          {" "}
          <ul className="socials-contact">
            <li>
              <a
                title="lien vers facebook de Marianne Derouet"
                href="https://m.facebook.com/marianne.ribot?refsrc=deprecated&_rdr"
              >
                <i className="fab fa-facebook"></i>
              </a>
            </li>
            <li>
              <a
                title="lien vers linkedin de Marianne Derouet"
                href="https://www.linkedin.com/in/marianne-derouet-9a3b011b2/"
              >
                <i className="fab fa-linkedin"></i>
              </a>
            </li>

            <li>
              <a
                title="lien vers l'instagram de Marianne Derouet"
                href="https://instagram.com/le.coin.qui.fait.du.bien?igshid=YmMyMTA2M2Y="
              >
                <i className="fab fa-instagram"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Contact;
