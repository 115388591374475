import React from "react";

const Footer = () => {
  return (
    <footer>
      <div className="footer-content">
        <h3> Marianne Derouet </h3>
        <p>
          <img src="blue-logo.png" alt="logo" />
        </p>
        <div className="footer-grid">
          <div className="footer-contact">
            <span>06 73 50 64 73 </span>
            <span className="contact-mail">mariannederouet@gmail.com</span>
          </div>
          <div className="footer-social">
            {" "}
            <ul className="socials">
              <li>
                <a
                  title="lien vers facebook de Marianne Derouet"
                  href="https://m.facebook.com/marianne.ribot?refsrc=deprecated&_rdr"
                >
                  <i className="fab fa-facebook"></i>
                </a>
              </li>
              <li>
                <a
                  title="lien vers linkedin de Marianne Derouet"
                  href="https://www.linkedin.com/in/marianne-derouet-9a3b011b2/"
                >
                  <i className="fab fa-linkedin"></i>
                </a>
              </li>

              <li>
                <a
                  title="lien vers l'instagram de Marianne Derouet"
                  href="https://instagram.com/le.coin.qui.fait.du.bien?igshid=YmMyMTA2M2Y="
                >
                  <i className="fab fa-instagram"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="footer-menu">
        <ul className="f-menu">
          <li>
            <a
              basename={`/${process.env.PUBLIC_URL}`}
              href="./Politiquedeconfidentialité.pdf"
              target="_blank"
            >
              Politiques de confidentialités
            </a>
          </li>
          <li>
            <a
              title="redirection vers les mentions légales"
              href="/mentions-legales"
            >
              Mentions légales
            </a>
          </li>
          <li>
            <a
              basename={`/${process.env.PUBLIC_URL}`}
              href="./arborescenceV2.pdf"
              target="_blank"
            >
              Plan du site
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
