import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Navigation from "./components/Navigation";
import AccompagnementDetail from "./pages/Accompagnements/AccompagnementDetail";
import HypnosePage from "./pages/HypnosePage";
import TherapiePage from "./pages/TherapiePage";
import GroupePage from "./pages/GroupePage";
import Articles from "./pages/Articles/Articles";
import ArticleDetail from "./pages/Articles/ArticleDetail";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Testimonial from "./pages/Testimonials";
import WhoIAm from "./pages/WhoIAm";
import ArticleForm from "./components/ArticleForm";
import AccompagnementForm from "./components/AccompagnementForm";
import TestimonialForm from "./components/TestimonialForm";
import Administrator from "./pages/Administrator";
import NotFound from "./pages/NotFound";
import { ProtectedRoute } from "./ProtectedRoute";
import LegalsMentions from "./pages/LegalsMentions";
import { Helmet } from "react-helmet";

function App() {
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

  return (
    <Router>
      <div className="main-container">
        <Header />
        <Helmet>
          <title>Marianne Derouet Thérapeute et coach</title>
          <meta name="description" content="Marianne Derouet Thérapeute" />
          <meta
            name="keywords"
            content="Thérapeute, Angers, Coach, Marianne Derouet"
          />
        </Helmet>
        <Navigation />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/mentions-legales" element={<LegalsMentions />} />
          <Route exact path="/Login" element={<Login />} />
          <Route exact path="/Quisuisje" element={<WhoIAm />} />
          <Route exact path="/Hypnose" element={<HypnosePage />} />
          <Route exact path="/Therapie-Coaching" element={<TherapiePage />} />
          <Route exact path="/Groupes" element={<GroupePage />} />
          {/* <Route exact path="/Accompagnements" element={<Accompagnements />} /> */}
          <Route
            exact
            path="/Accompagnements/:id"
            element={<AccompagnementDetail />}
          />
          <Route exact path="/Articles" element={<Articles />} />
          <Route exact path="/Articles/:id" element={<ArticleDetail />} />
          <Route exact path="/Testimonials" element={<Testimonial />} />
          <Route exact path="/Contacts" element={<Contact />} />
          {/* <Route exact path="/Signin" element={<SignIn />} /> */}
          {/* <Route exact path="/RequestPassword" element={<ResetPassword />} />
          <Route
            exact
            path="/reset-password/:id"
            element={<PasswordFormReset />}
          /> */}
          {userInfo ? (
            <>
              <Route
                exact
                path="/AccompagnementsAdd"
                element={
                  <ProtectedRoute redirectPath="/" isAllowed={userInfo.admin}>
                    <AccompagnementForm isEditForm={false} />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/AccompagnementsUpdate/:id"
                element={
                  <ProtectedRoute redirectPath="/" isAllowed={userInfo.admin}>
                    <AccompagnementForm isEditForm={true} />
                  </ProtectedRoute>
                }
              />

              <Route
                exact
                path="/ActusAdd"
                element={
                  <ProtectedRoute redirectPath="/" isAllowed={userInfo.admin}>
                    <ArticleForm isEditForm={false} />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/ActusUpdate/:id"
                element={
                  <ProtectedRoute redirectPath="/" isAllowed={userInfo.admin}>
                    <ArticleForm isEditForm={true} />
                  </ProtectedRoute>
                }
              />

              <Route
                exact
                path="/TestimonialsAdd"
                element={
                  <ProtectedRoute redirectPath="/" isAllowed={userInfo.admin}>
                    <TestimonialForm isEditForm={false} />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/TestimonialsUpdate/:id"
                element={
                  <ProtectedRoute redirectPath="/" isAllowed={userInfo.admin}>
                    <TestimonialForm isEditForm={true} />
                  </ProtectedRoute>
                }
              />
              {/* <Route exact path="/Account" element={<Account />} />
              <Route exact path="/Account/:id" element={<UserForm />} /> */}
              <Route
                exact
                path="/Admin"
                element={
                  <ProtectedRoute redirectPath="/" isAllowed={userInfo.admin}>
                    <Administrator />
                  </ProtectedRoute>
                }
              />
            </>
          ) : (
            ""
          )}
          <Route path="*" element={<NotFound />} />
        </Routes>

        <Footer />
      </div>
    </Router>
  );
}

export default App;
