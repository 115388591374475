import React, { useState, useEffect, useRef } from "react";

function DropDownButton() {
  // Utilisation d'un state pour contrôler l'affichage du menu déroulant
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const menuRef = useRef(null); // Référence pour le menu déroulant

  // Fonction pour gérer le clic sur le lien principal
  const handleLinkClick = (e) => {
    e.preventDefault();
    setIsDropdownOpen((prev) => !prev); // Inverser l'état du menu déroulant
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  const handleOutsideClick = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      closeDropdown();
    }
  };

  useEffect(() => {
    if (isDropdownOpen) {
      // Ajouter l'écouteur d'événements pour les clics en dehors du menu
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      // Supprimer l'écouteur d'événements lorsque le menu est fermé
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    // Nettoyer l'écouteur lors du démontage du composant
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isDropdownOpen]);

  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      <a
        className="link-rdv"
        href="#"
        onClick={handleLinkClick}
        target="_blank"
        rel="noreferrer"
      >
        <span>Prendre un rendez-vous</span>
      </a>

      {isDropdownOpen && (
        <div
          ref={menuRef}
          style={{
            position: "absolute",
            top: "100%", // Positionnement vertical
            left: "0%", // Positionnement horizontal centré
            transform: "translateX(20%)", // Pour centrer horizontalement
            backgroundColor: "#f5f0e1",
            boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)",
            zIndex: 1,
            width: "200px", // Vous pouvez ajuster cette largeur selon vos besoins
            display: "flex", // Utilisation de flexbox pour étendre le contenu
            flexDirection: "column",
            padding: "10px",
          }}
        >
          <a
            href="https://calendly.com/marianne-derouet/rdv-presentiel-mariannederouet"
            target="_blank"
            rel="noreferrer"
            onClick={closeDropdown} // Fermer le menu lors du clic
            style={{
              display: "block",
              padding: "8px 16px",
              textDecoration: "none",
              color: "black",
            }}
          >
            RdV en Présentiel
          </a>
          <a
            href="https://calendly.com/marianne-derouet/rdv-visio-mariannederouet"
            target="_blank"
            rel="noreferrer"
            onClick={closeDropdown} // Fermer le menu lors du clic
            style={{
              display: "block",
              padding: "8px 16px",
              textDecoration: "none",
              color: "black",
            }}
          >
            RdV en visio
          </a>
        </div>
      )}
    </div>
  );
}

export default DropDownButton;
