export const emailValidator = (email) => {
  if (!email) {
    return "L'email est requis";
  } else if (!new RegExp(/\S+@\S+\.\S+/).test(email)) {
    return "Format de l'email incorrect";
  }
  return "";
};

export const lastnameValidator = (lastname) => {
  if (!lastname) {
    return "Le nom est requis.";
  } else if (!new RegExp(/^[a-zA-Z\s,'-éàùêî]*$/).test(lastname)) {
    return "Format du nom incorrect";
  }
  return "";
};

export const messageValidator = (message) => {
  if (
    !new RegExp(/^[a-zA-Z\s,'-éàùêî]*$/).test(message) &&
    message.length > 0
  ) {
    return "Format du message incorrect";
  }
  return "";
};

export const firstnameValidator = (firstname) => {
  if (!firstname) {
    return "Le prénom est requis";
  } else if (!new RegExp(/^[a-zA-Z\s,'-éàùêîë]*$/).test(firstname)) {
    return "Format du prénom incorrect";
  }
  return "";
};

export const phoneValidator = (phone_number) => {
  if (!new RegExp(/[0-9]+$/).test(phone_number) && phone_number.length > 0) {
    return "Format du numéro de téléphone incorrect";
  }
  return "";
};

export const addressValidator = (address) => {
  if (!address) {
    return "L'addresse est requise";
  } else if (!new RegExp(/^[a-zA-Z0-9\s,'-éàùêî]*$/).test(address)) {
    return "Format de l'adresse incorrect";
  }
  return "";
};

export const cityValidator = (city) => {
  if (!city) {
    return "La ville est requise";
  } else if (!new RegExp(/^[a-zA-Z\s,'-éàùêî]*$/).test(city)) {
    return "Format de la ville incorrect";
  }
  return "";
};

export const countryValidator = (country) => {
  if (!country) {
    return "Le pays est requise";
  } else if (!new RegExp(/^[a-zA-Z\s,'-éàùêî]*$/).test(country)) {
    return "Format du pays incorrect";
  }
  return "";
};

export const passwordValidator = (password) => {
  if (!password) {
    return "Le mot de passe est requis";
  } else if (
    !new RegExp(
      /^(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/
    ).test(password)
  ) {
    return "Le mot de passe doit contenir au moins 9 caractères dont 1 chiffre, 1 minuscule, 1 majuscule";
  }
  return "";
};

export const confirmPasswordValidator = (confirmPassword, form) => {
  if (!confirmPassword) {
    return "La confirmation du mot de passe est requise";
  } else if (
    !new RegExp(
      /^(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/
    ).test(confirmPassword)
  ) {
    return "Le mot de passe doit contenir au moins 9 caractères dont 1 chiffre, 1 minuscule, 1 majuscule";
  } else if (confirmPassword !== form.password) {
    return "La confirmation est différente du mot de passe";
  }
  return "";
};
